<script setup lang="ts"></script>

<template>
  <svg
    width="15"
    height="10"
    viewBox="0 0 15 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="absolute top-2 sm:top-3 right-3"
  >
    <path
      d="M13.5008 1.6116L7.35588 8.38867L1.21094 1.6116"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<style scoped></style>
